<template>
  <div :class="$style.orders">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск"
          :valueNames="['name']"
          @querySearch="querySearch"
          @selectItem="handleSelectOrder"
          @handleFindItems="handleFindOrders"
          @input="search = $event"
        />
        <el-button
          :class="$style.button"
          style="margin-left: 0; margin-bottom: 0; border-radius: 0"
          type="primary"
          @click="getList"
        >
          Показать
        </el-button>
      </div>
      <el-button
        size="big"
        type="primary"
        :class="$style.button"
        @click="$router.push('orders/create')"
        >Создать заказ</el-button
      >
    </div>
    <el-table :data="orders" stripe>
      <el-table-column prop="name" label="Название"> </el-table-column>
      <el-table-column prop="paymentStatus" label="Статус оплаты">
        <template slot-scope="scope">
          {{ scope.row.paymentStatus.name }}
        </template>
      </el-table-column>
      <el-table-column prop="deliveryType" label="Тип доставки">
        <template slot-scope="scope">
          {{ getDeliveryType(scope.row.deliveryType) }}
        </template>
      </el-table-column>
      <el-table-column label="Тип заказа">
        <template slot-scope="scope">
          {{ scope.row.type === 'GENERAL' ? 'Обычный' : 'Быстрый' }}
        </template>
      </el-table-column>
      <el-table-column prop="deliveryAt" label="Дата заказа" width="120">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="deliveryAt" label="Дата доставки" width="120">
        <template slot-scope="scope">
          {{ scope.row.deliveryAt ? formatDate(scope.row.deliveryAt) : '-' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="isExpressDelivery"
        label="Экспресс доставка"
        width="90"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isExpressDelivery"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column prop="price" label="Цена" width="90">
        <template slot-scope="scope">
          {{
            scope.row.price -
              (scope.row.discount ? scope.row.discount : 0) +
              scope.row.deliveryPrice >
            0
              ? scope.row.price -
                (scope.row.discount ? scope.row.discount : 0) +
                scope.row.deliveryPrice
              : 0
          }}
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot-scope="scope">
          <ActionButtons
            :view-link="'orders/' + String(scope.row.id)"
            :edit-link="'orders/' + String(scope.row.id) + '/edit'"
            @delete="remove(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/index.js'
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import { DELIVERY_TYPE_DISPLAY } from '@/constants/deliveryType'

export default {
  components: { ActionButtons, Autocomplete },
  data() {
    return {
      orders: [],
      total: 0,
      page: 1,
      limit: 20,
      search: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
      }

      if (this.search) {
        query.search = this.search
      }

      const { value, error } =
        await delivery.PaymentServiceCore.GeneralOrdersActions.getList(query)

      if (error) return

      this.orders = value.data
      this.total = value.meta.count

      loading.close()
    },
    async remove() {
      alert('Функция недоступна')
    },
    formatDate(date) {
      return formatDate(date)
    },
    handleSelectOrder(selectedOrder) {
      this.$router.push('/addwine/orders/' + selectedOrder.id)
    },
    async handleFindOrders() {
      await this.getList()
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.PaymentServiceCore.GeneralOrdersActions.getList({
          limit: this.limit,
          page: this.page,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.data)
    },

    getDeliveryType(deliveryType) {
      return DELIVERY_TYPE_DISPLAY[deliveryType]
    },
  },
}
</script>

<style lang="scss" module>
.orders {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    .search {
      display: flex;
      align-items: center;
      margin-right: auto;
      input {
        border-radius: 0;
      }
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
